import { ErrorPageWrapper } from '@zep/components/error/ErrorPageWrapper.tsx';
import { ErrorWidget } from '@zep/components/error/ErrorWidget';
import { getEmptyLayout } from '@zep/layout';
import { Button } from '@zep/ui';
import { getDefaultStaticProps } from '@zep/utils';
import { NextPageWithLayout } from 'next';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

const Quiz404: NextPageWithLayout = () => {
  const router = useRouter();
  const { t } = useTranslation();

  return (
    <ErrorPageWrapper>
      <ErrorWidget.Root>
        <ErrorWidget.Header>
          <ErrorWidget.Image src={'/assets/error_404.png'} />
        </ErrorWidget.Header>
        <ErrorWidget.Content>
          <ErrorWidget.Title>
            {t('common.notFoundPage.title')}
          </ErrorWidget.Title>
          <ErrorWidget.Description>
            {t('common.notFoundPage.content')}
          </ErrorWidget.Description>
        </ErrorWidget.Content>
        <ErrorWidget.Footer>
          <Button size="lg" onClick={() => router.push('/landing')}>
            {t('common.notFoundPage.button')}
          </Button>
        </ErrorWidget.Footer>
      </ErrorWidget.Root>
    </ErrorPageWrapper>
  );
};

Quiz404.getLayout = getEmptyLayout;

export const getStaticProps = getDefaultStaticProps;

export default Quiz404;
