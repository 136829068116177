import { ComponentProps, ComponentPropsWithoutRef } from 'react';
import { cn } from '@zep/utils';

type WrapperProps = ComponentProps<'div'>;

const ErrorWidgetRoot = (props: WrapperProps) => {
  const { children, className, ...rest } = props;
  return (
    <div
      className={cn(
        'flex flex-col justify-center items-center max-w-[600px]',
        className,
      )}
      {...rest}>
      {children}
    </div>
  );
};

const ErrorWidgetHeader = (props: WrapperProps) => {
  const { children, className, ...rest } = props;
  return (
    <div className={cn('', className)} {...rest}>
      {children}
    </div>
  );
};

const ErrorWidgetContent = (props: WrapperProps) => {
  const { children, className, ...rest } = props;
  return (
    <div
      className={cn(
        'flex flex-col items-center mt-[40px] gap-md justify-center',
        className,
      )}
      {...rest}>
      {children}
    </div>
  );
};

const ErrorWidgetFooter = (props: WrapperProps) => {
  const { children, className, ...rest } = props;
  return (
    <div
      className={cn('mt-[24px] flex gap-[10px] items-center', className)}
      {...rest}>
      {children}
    </div>
  );
};

// ********************************************************

const ErrorWidgetImage = (
  props: ComponentPropsWithoutRef<'img'> & { src: string },
) => {
  const { alt, src, className, ...rest } = props;
  return (
    <img
      src={src}
      alt={alt || ''}
      className={cn('h-[150px]', className)}
      {...rest}
    />
  );
};

const ErrorWidgetTitle = (props: ComponentProps<'h3'>) => {
  const { children, className, ...rest } = props;
  return (
    <div
      className={cn(
        'text-default font-extrabold text-heading-lg whitespace-pre-line text-balance text-center',
        className,
      )}
      {...rest}>
      {children}
    </div>
  );
};

const ErrorWidgetDescription = (props: ComponentPropsWithoutRef<'p'>) => {
  const { children, className, ...rest } = props;
  return (
    <p
      className={cn(
        'text-neutral whitespace-pre-line text-pretty text-body-md text-center',
        className,
      )}
      {...rest}>
      {children}
    </p>
  );
};

// ********************************************************

export const ErrorWidget = {
  Root: ErrorWidgetRoot,
  Header: ErrorWidgetHeader,
  Content: ErrorWidgetContent,
  Footer: ErrorWidgetFooter,
  Image: ErrorWidgetImage,
  Title: ErrorWidgetTitle,
  Description: ErrorWidgetDescription,
};
